import cn from 'classnames';
import type { FC, ReactNode } from 'react';
import React, { memo, useMemo } from 'react';

import { Typography } from '@sravni/react-design-system';

import styles from './styles.module.scss';

const { Text } = Typography;

interface IContentItemProps {
    className?: string;
    dataClassName?: string;
    title: string;
    data: ReactNode;
    onClick?: (event: React.SyntheticEvent) => void;
    classNameTitle?: string;
    classNameData?: string;
    wrapDataByString?: string;
}

const ContentItem: FC<IContentItemProps> = memo(({ className, classNameTitle, classNameData, title, data, dataClassName, onClick, wrapDataByString }) => {
    const content = useMemo(() => wrapDataByString && typeof data === 'string'
        ? (data.split(wrapDataByString).map((value, index) => (index ? <><br /> {wrapDataByString}{value}</> : value)))
        : data, [data, wrapDataByString]);

    return (
        <div className={cn(styles.wrapper, className)}>
            <Text className={cn(styles.title, classNameTitle)}>{title}</Text>
            <Text className={cn(dataClassName, styles.data, classNameData)} onClick={onClick}>
                {content}
            </Text>
        </div>
    )
});

export { ContentItem };
