import { useMemo } from 'react';

import { useAbTestingSdk } from '@sravni/react-utils';

import type { Experiments } from '../constants/abTest';
import { A_TEST_VALUE, B_TEST_VALUE, C_TEST_VALUE } from '../constants/abTest';

export const useTestVariant = (experimentId: Experiments) => {
    const abTestingSdk = useAbTestingSdk();

    return useMemo(
        () => ({
            isAVariant: abTestingSdk.checkExperimentVariant(experimentId, A_TEST_VALUE),
            isBVariant: abTestingSdk.checkExperimentVariant(experimentId, B_TEST_VALUE),
            isCVariant: abTestingSdk.checkExperimentVariant(experimentId, C_TEST_VALUE),
        }),
        [experimentId, abTestingSdk],
    );
};
