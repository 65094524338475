import React from 'react';

import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';
import { sendAdvertisingButtonClickEvent, sendOrganizationOpenEvent } from '@src/helpers/analyticsEvents';

interface IProps {
    className?: string;
    analytics?: IAnalyticsItem;
    link?: { href?: string; withAdvertising: boolean };
    onClick?: () => void;
}

const Link: React.FC<IProps> = ({ className, analytics, link, children, onClick }) => {
    if (!link?.href) {
        return <div className={className}>{children}</div>;
    }

    if (link.withAdvertising) {
        const handleMonetizationClick = (evt: React.MouseEvent) => {
            evt.stopPropagation();
            evt.preventDefault();

            onClick?.();

            if (analytics) {
                sendAdvertisingButtonClickEvent({
                    ...analytics,
                    advertising: analytics.advertising && {
                        ...analytics.advertising,
                        buttonEventAction: 'Платный клик|Логотип',
                    },
                });
            }

            window.open(link.href, '_blank', 'noopener');
        };

        return (
            <a className={className} onClick={handleMonetizationClick}>
                {children}
            </a>
        );
    }

    const handleClick = (evt: React.MouseEvent) => {
        evt.stopPropagation();

        onClick?.();

        if (analytics) {
            sendOrganizationOpenEvent(analytics);
        }
    };

    return (
        <a className={className} href={link.href} onClick={handleClick}>
            {children}
        </a>
    );
};

export default Link;
