import { GOOD_OFFER_IDS } from '../types';

export const getTemporaryTerm = (organizationId: string, term: string) => {
    switch (organizationId) {
        case GOOD_OFFER_IDS.WEBBANKIR:
            return 'от 61 до 90';

        case GOOD_OFFER_IDS.ZAJMIGO:
            return 'от 61 до 90';

        case GOOD_OFFER_IDS.EKAPUSTA:
            return 'от 61 до 180';

        case GOOD_OFFER_IDS.KVIKU:
            return 'от 61 до 365';

        case GOOD_OFFER_IDS.MONEYMAN:
            return 'от 61 до 364';

        default:
            return term;
    }
};
